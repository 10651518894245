<template>
  <div class="way-item">
    <div class="way-item-wrapper">
      <div :class="`icon icon-${data.icon}`"></div>

      <div class="way-item-number">{{ data.step }}</div>
    </div>

    <p class="way-item-text">
      {{ data.title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
