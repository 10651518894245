<template>
  <main id="thank-next" class="next-container thank">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 custom-margins mobile-h2">Vielen Dank!</h1>

        <p class="text-subtitle color-accent-300">
          Vielen Dank! Wir freuen uns, dass Sie sich für eines unserer
          individuellen Strompakete entschieden haben. Unser Team kümmert sich
          um Ihre Bestellung und begleitet Sie bei den nächsten Schritten.
        </p>

        <div class="image-block">
          <img src="~@/assets/images/images-next/familie.png" alt="" />
        </div>
      </div>

      <div class="heading-col top mobile-top">
        <h1 class="color-primary-100 mobile-h2 ">Nächste Schritte</h1>
      </div>

      <way-wrapper>
        <way-item v-for="way in ways" :key="way.step" :data="way"></way-item>
      </way-wrapper>
    </section-base>

    <smart-meter-offer v-if="showOfferSection" />

    <section-base v-else>
      <a
        href="https://www.eprimo.de"
        target="_blank"
        class="button button-accent"
      >
        eprimo.de besuchen
      </a>
    </section-base>
  </main>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import WayWrapper from '@/components/next/way/way-wrapper.vue';
import WayItem from '@/components/next/way/way-item.vue';
import { ways } from '@/placeholder/cta';
import { mapState } from 'vuex';
import SmartMeterOffer from '../../components/next/smart-meter-offer.vue';

export default {
  components: { SmartMeterOffer, WayWrapper, WayItem, SectionBase },

  data: () => ({ ways }),

  computed: {
    ...mapState('user', ['offer']),

    showOfferSection() {
      return !!this.offer?.user?.SmartMeter_Offer;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
