<template>
  <section-base>
    <div class="heading-col">
      <h1 class="text-title color-primary-100 custom-margins mobile-h2">
        Noch mehr Komfort und Übersicht<br />
        mit dem Smart Meter
      </h1>

      <p class="text-subtitle color-accent-300">
        eprimo testet derzeit, ob wir mithilfe eines modernen Stromzählers die
        Strompakete noch attraktiver und einfacher gestalten können und bietet
        daher zu den bestehenden Strompaketen exklusiv für eine kleine
        Testgruppe und einen befristeten Zeitraum kostenfrei einen neuen,
        modernen und smarten Stromzähler an.
      </p>
    </div>

    <div class="smartmeter-block">
      <div class="smartmeter-illustration">
        <div class="smartmeter-icon"></div>
      </div>

      <div class="smartmeter-list">
        <div
          v-for="(item, idx) in smartmeterCTA"
          :key="idx"
          class="smartmeter-list-wrapper smartmeter-list-wrapper-margin"
        >
          <cta-list-base>
            <h4 class="cta-list-title">{{ item.title }}</h4>

            <cta-list-item
              v-for="(string, strx) in item.strings"
              :key="strx"
              :darken="true"
              :title="string"
            />
          </cta-list-base>
        </div>
      </div>
    </div>

    <p class="text-subtitle color-accent-300">
      Bestellen Sie jetzt das zurzeit kostenlose eprirno SmartMeter-Angebot. Wir
      übernehmen als wettbewerblicher Messstellenbetreiber Ihren
      Messstellenbetrieb und Sie müssen keine Zählerstände mehr melden. Im
      nächsten Schritt kontaktieren wir Sie kurzfristig und vereinbaren einen
      Termin zum Austausch Ihres alten Stromzählers.
    </p>

    <div class="smartmeter-button">
      <button-regular @click="submitOrderSmartMeter()" :class="'button-accent'"
        >Jetzt kostenfrei bestellen</button-regular
      >
    </div>
  </section-base>
</template>

<script>
import { smartmeterCTA } from '@/placeholder/cta';
import CtaListItem from '@/components/next/cta/cta-list-item.vue';
import CtaListBase from '@/components/next/cta/cta-list-base.vue';
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { CtaListItem, CtaListBase, ButtonRegular, SectionBase },

  data: () => ({
    smartmeterCTA,
    isLoading: false
  }),

  methods: {
    submitOrderSmartMeter() {
      this.isLoading = true;

      this.$store
        .dispatch('user/SMART_METER_ORDER_SUBMIT', {
          uuid: this.$route.params.uuid
        })
        .then(res => {
          if (res.data?.success) {
            this.$router.push({ name: 'ThankYouSmartMeterPage' });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
